import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import cn from 'classnames';
import {
  Button,
  Grid,
  IntersectionAnimation
} from '@livewire/common/components';
import { HoverLogo } from '@livewire/website/src/components';
import { IFooter } from '@livewire/sanity';

import * as styles from './styles.module.scss';

const Footer = () => {
  const settings: IFooter = useStaticQuery(graphql`
    query {
      sanitySettings {
        footer {
          legal
          copyright

          socialLinks {
            text
            url
            _key
          }

          internalLinks {
            title
            slug {
              current
            }
            _id
          }
        }
      }
    }
  `)?.sanitySettings?.footer;

  const { legal, copyright, socialLinks, internalLinks } = settings || {};

  const date = new Date();
  const animationStagger = 100;

  return (
    <footer className={styles.container}>
      <Grid>
        <IntersectionAnimation className={styles.logo} margin="-150px">
          <Link className={styles.logoLink} to="/" aria-label="Go to homepage">
            <HoverLogo color="black" />
            {/* <SVG svg="lockup" className={styles.lockup} /> */}
          </Link>
        </IntersectionAnimation>

        {socialLinks?.[0] && (
          <ul className={styles.socialLinks}>
            {socialLinks.map((socialLink, index) => {
              const { _key, text, url } = socialLink || {};

              return (
                <li key={`footer-social-link-${_key}`}>
                  <IntersectionAnimation delay={animationStagger * index}>
                    <Button variant="text" href={url}>
                      {text}
                    </Button>
                  </IntersectionAnimation>
                </li>
              );
            })}
          </ul>
        )}

        {internalLinks?.[0] && (
          <ul className={styles.internalLinks}>
            {internalLinks.map((internalLink, index) => {
              const { _id, title, slug } = internalLink || {};

              return (
                <li key={`footer-internal-link-${_id}`}>
                  <IntersectionAnimation
                    delay={animationStagger * (index + socialLinks?.length)}
                  >
                    <Button variant="text" to={`/${slug?.current}`}>
                      {title}
                    </Button>
                  </IntersectionAnimation>
                </li>
              );
            })}
          </ul>
        )}

        <IntersectionAnimation
          className={styles.body}
          delay={
            animationStagger * (internalLinks?.length + socialLinks?.length)
          }
          margin="0px"
        >
          {legal && <p className={cn('b2', styles.legal)}>{legal}</p>}
          {copyright && (
            <p className={cn('b2', styles.copyright)}>{copyright}</p>
          )}
        </IntersectionAnimation>
      </Grid>
    </footer>
  );
};

export default Footer;
