import React, { useEffect, useState } from 'react';
import {
  PasswordForm,
  Theme,
  Menu,
  Header,
  Footer
} from '@livewire/website/src/components';
import { NoJs } from '@livewire/common/components';
import 'modern-normalize/modern-normalize.css';
import '@livewire/common/styles/global.css';

import * as styles from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  location: {
    pathname: string;
  };
  pageContext: {
    id: string;
    password?: string;
  };
}

const Layout = ({ children, location, pageContext }: IProps) => {
  const { password } = pageContext || null;

  const isPasswordEnabled = password !== undefined && password !== null;

  const [isPageLocked, setIsPageLocked] = useState(isPasswordEnabled);
  const [passwordInputValue, setPasswordInputValue] = useState('');

  const pathname = location?.pathname;
  const sanitizedPathname = pathname.replace(/\//g, '');

  const handleSubmitPassword = () => {
    if (passwordInputValue.trim() === password) {
      setIsPageLocked(false);
    }
  };

  useEffect(() => {
    setIsPageLocked(isPasswordEnabled);
  }, [pathname]);

  return (
    <>
      <NoJs />
      <Theme />

      <Menu pathname={sanitizedPathname} />

      <Header pathname={sanitizedPathname} />

      <div className={styles.container}>
        {(isPageLocked && (
          <PasswordForm
            password={password}
            handleSubmitPassword={handleSubmitPassword}
            setPasswordInputValue={setPasswordInputValue}
          />
        )) ||
          children}
      </div>

      {!isPageLocked && <Footer />}
    </>
  );
};

export default Layout;
